<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar title="充值" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div style="padding-top:46px;background: #f5f5f5;">
      <div class="fangs">请选择付款方式</div>
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="微信支付" clickable @click="radio = '1'" :icon="weixin">
            <template #right-icon>
              <van-radio name="1" checked-color="#07c160" icon-size="18px" />
            </template>
          </van-cell>
          <!-- @click="radio = '2'" -->
          <van-cell title="支付宝" clickable :icon="zhifubao">
            <template #right-icon>
              <van-radio disabled name="2" checked-color="#07c160" icon-size="18px" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <!-- <span>{{isWxMini}}</span> -->
      <div class="yuer">充值金额</div>
      <!-- <van-cell-group>
        <van-field v-model="value" placeholder="请输入用户名" />
      </van-cell-group>-->
      <div style="background: #fff;">
        <div class="inptt">
          <span style="font-weight: bold">￥</span>
          <input :readonly="modelId != ''" class="icpt" type="number" v-model="activityPrice" />
        </div>
      </div>
      <van-row gutter="0" class="cz_lis">
        <van-col
          class="cz_list"
          v-for="(item,idx) in list"
          @click="toxuan(item)"
          :key="idx"
          :class="item.id==modelId?'isbeijin':''"
        >
          <div class="c_ls_a">
            {{item.balance}}元
            <span style="font-size: 12px;">赠送{{item.giftBalance}}元</span>
          </div>
          <div
            class="c_ls_b"
            v-for="(items,idxs) in item.giftItems"
            :key="idxs"
          >{{items.giftItem}}{{items.count}}次</div>
        </van-col>
      </van-row>
      <van-button type="primary" class="anniu" @click="chongzhi">充值</van-button>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      list: [],
      radio: "1",
      modelId: "",
      loading: false,
      weixin: require("../../assets/img/weixin.png"),
      zhifubao: require("../../assets/img/zhifubao.png"),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      total: 0,
      activityUrl: "",
      isWxMini: "",
      activityPrice: "",
      chizhimb: this.$route.query.chizhimb,
      finished: false
    };
  },
  methods: {
    xiangqing(item) {
      this.$router.push({
        path: "member_details"
      });
      localStorage.setItem("huiyuan", JSON.stringify(item));
    },
    chongzhi() {
      var _this = this;
      var province = this.shangjia.province
        ? this.shangjia.province.toString()
        : "";
      var city = this.shangjia.city ? this.shangjia.city.toString() : "";
      var district = this.shangjia.district
        ? this.shangjia.district.toString()
        : "";
      var address = this.shangjia.address
        ? this.shangjia.address.toString()
        : "";
      var areaDetail = province + city + district + address;
      var lis = {
        activityPrice: this.activityPrice,
        id: 0,
        // customerId: this.userInfo.customerId,
        gid: this.shangjia.id,
        modelId: this.modelId,
        payType: this.radio,
        type: 5,
        areaDetail:areaDetail, //维修厂详细地址
        carModel: this.chexin ? this.chexin.carBrand : "", //车型
        carNo: this.chexin ? this.chexin.carNo : "", //车牌
        garageName: this.shangjia.name, //修理厂名称
        positionJd: this.shangjia.positionJd,
        positionWd: this.shangjia.positionWd,
        tel: this.shangjia.legalPersonPhone,
        receiver: this.shangjia.legalPerson, //用户名称
        token: ""
      };
      var isWxMini = window.__wxjs_environment === "miniprogram";
      console.log(isWxMini);
      if (isWxMini) {
        lis.token = localStorage.getItem("Authorization");
        lis.ccisok = 5; //3:会员卡订单 4:套餐订单 5:充值卡订单
        let payDataStr = JSON.stringify(lis);
        wx.miniProgram.navigateTo({
          url: "/pages/pay/pay?payDataStr=" + payDataStr // 微信小程序调用支付的页面
        });
      } else {
        //  这里放原理的支付逻辑
        orderModel.goumaivip(lis).then(res => {
          if (res.code == 200) {
            if (typeof WeixinJSBridge === "undefined") {
              // 微信浏览器内置对象。参考微信官方文档
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
              }
            } else {
              console.log("准备调用微信支付");
              _this.onBridgeReady(res.data);
            }
          }
        });
      }
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast("充值成功");

            setTimeout(() => {
              vm.$router.go(-1);
            }, 1000);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    onLoad() {
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      // const data = {
      //   gid: this.shangjia.id
      // };
      // orderModel
      //   .taocanlist(data)
      //   .then(e => {
      //     loading.clear();
      //     this.list = e.data;
      //   })
      //   .catch(() => loading.clear());
    },
    onLoads() {},
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    toxuan(item) {
      console.log("asdasdas");
      if (item.id == this.modelId) {
        this.modelId = "";
        this.activityPrice = "";
      } else {
        this.modelId = item.id;
        this.activityPrice = item.balance;
        this.giftBalance = item.giftBalance;
      }
    },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });f
    }
  },

  created() {
    // this.onLoad();
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
    if (this.chizhimb == "true") {
      const data = {
        gid: this.shangjia.id,
        status: 1
      };
      orderModel
        .chizhikalist(data)
        .then(e => {
          this.list = e.data;
        })
        .catch(() => loading.clear());
    }
    // console.log(isWxMini);
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.fangs {
  padding: 24px 12px 7px 12px;
  font-size: 14px;
  color: #666;
}
.van-icon__image {
  width: 20px;
  height: 20px;
  margin-top: 3px;
}
.yuer {
  background: #fff;
  margin-top: 15px;
  border-radius: 15px 15px 0 0;
  padding: 20px 20px 0px 20px;
  height: 100%;
  // padding: 20px 15px;
  font-size: 14px;
  margin-top: 15px;
}
.inptt {
  padding: 15px 0;
  font-size: 26px;
  margin: 0 20px;
  background: #fff;
  border-bottom: 1px solid #f5f5f5;
}
.icpt {
  margin-left: 7px;
  display: inline-block;
  width: 80%;
  border: none;
}
.anniu {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  left: 0;
  // margin-left: -45%;
  z-index: 99;
}
.cz_lis {
  padding: 12px 20px;
  background: #fff;
  font-size: 14px;
}
.cz_list {
  height: 80px;
  background: #f5f5f5;
  background-clip: content-box;
  text-align: center;
  border-radius: 5px;
  width: 47%;
}
.cz_list:nth-child(odd) {
  float: left;
}
.cz_list:nth-child(even) {
  float: right;
}
.c_ls_a {
  margin-top: 15px;
  font-size: 14px;
}
.c_ls_b {
  font-size: 12px;
  color: #666;
  line-height: 16px;
}
.isbeijin {
  color: #fff;
  background: #0d906e;
}
.isbeijin .c_ls_b {
  color: #fff;
}
</style>